export const testimonials = [
    {
        id:1,
        qn: "What features does the tuition management software offer?",
        ans:"Tuition management software typically includes features such as student enrollment, tuition fee calculation, payment plan generation, billing and invoicing, financial aid management, reporting, and communication tools.",  type:"Types1"
    },
    {
        id:2,
        qn: "Is the tuition management software customizable to fit the specific needs of our educational institution?",
        ans:"Many tuition management software platforms offer customization options to tailor the software to the unique requirements of your educational institution, including customizable payment plans, fields, and workflows.",
        type:"Types2"
    },
    {
        id:3,
        qn:  "How user-friendly is the tuition management software for both administrators and students/parents?",
        ans:"Tuition management software is designed to be user-friendly, with intuitive interfaces and easy navigation. Most platforms offer training resources, tutorials, and customer support to help users get started and troubleshoot any issu ",
        type:"Types3"
    },
    {
        id:4,
        qn: "Can the tuition management software handle tasks such as enrollment management, billing, and financial aid efficiently?",
        ans:"Yes, top tuition management software platforms are capable of streamlining enrollment management, calculating tuition fees, generating payment plans, managing billing and invoicing, and handling financial aid processes efficient ",
        type:"Types4"
    },
    {
        id:5,
        qn: " Does the tuition management software offer integration with other educational systems or third- party applications?",
        ans:"Many tuition management software platforms offer integrations with student information systems, accounting software, and other educational systems, allowing for seamless data sharing and workflow automation across different platforms.",
        type:"Types5"
    },
]