import React, { useState } from 'react'
import { testimonials } from '../utils/variable/testimonial';

export const Testimonial = () => {
    const [selectedType, setSelectedType] = useState("Types1");

    const handleTypeClick = (type) => {
    setSelectedType(type);
    };
  return (
    <>
        <div>
          <h2
            style={{
              fontSize: "80px",
              color: "#345261",
              marginBottom: "10%",
              marginTop: "10%",
            }}
          >
            {" "}
            <b>FAQs</b>
          </h2>
        </div>

        <div className="row">
          <div className="col-sm-6">
            {
                testimonials.map((item)=>(
                    <div
                    className={`card mb-3 ${
                        selectedType === item?.type ? "boxborders-gradient-3" : ""
                    }`}
                    onClick={() => handleTypeClick(item.type)}
                    >
                    <div className="card-body">
                        <p
                        className={`card-title ${
                            selectedType === item?.type ? "active-bold" : ""
                        }`}
                        style={{ textAlign: "start" }}
                        >
                        {item.qn}
                        </p>
                    </div>
                    </div>
                ))
            }
          </div>
          <div className="col-sm-6">
            <div className="card boxborders-gradient-4 ">
              <div className="card-body">
                {
                    testimonials.map((item)=>(
                    selectedType === item.type && (
                    <div>
                        <h4
                        className="card-title mb-3 mt-3"
                        style={{ textAlign: "start" }}
                        >
                        {" "}
                        <b>
                           {item?.qn}
                        </b>
                        </h4>
                        <p className="p-2">
                            {item?.ans}
                        </p>
                    </div>
                    )))
                }
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
