export const meaning = "Our Tuition Management Software is a complete, easy-to-use platform that streamlines operations for tuition centres. Thedashboard provides quick insights into student enrolments, fee status, and upcoming classes. Manage enquiries effectively with tracking and follow-up tools that boost conversions. Simplify admissions by organizing student data for efficient onboarding. Fee management tools help track payments, send reminders, and streamline collection. Track and analyse expenses to optimize financial health. Generate professional invoices with ease, and schedule courses using intuitive course management features. Customize settings for roles and permissions to match your centre’s unique needs. Experience seamless, efficient tuition management today"

export const whyNeed = "Our Tuition Management Software simplifies every aspect of managing your tuition centre from tracking enrolments and fees to organizing courses and communications. Save time, reduce paperwork, and improve efficiency with an all-in-one platform. Focus on what matters most: delivering quality education."

export const features = [
    {
        id: 1,
        title: "Dashboard Overview",
        content: "Real-time insights into student numbers, fee collections, schedules, and recent activities for quick decision-making.",
        img: "01"
    },
    {
        id: 2,
        title: "Enquiry",
        content: "Capture, track, and follow up on new enquiries to convert leads into admissions efficiently.",
        img: "02"
    },
    {
        id: 3,
        title: "Admission Management",
        content: "Simplified onboarding with organized data collection, status tracking, and batch assignments.",
        img: "03"
    },
    {
        id: 4,
        title: "Fee and Payment Management",
        content: "Setup fee structures, automate reminders, collect payments, and generate receipts.",
        img: "04"
    },
    {
        id: 5,
        title: "Expense Tracking",
        content: "Record, categorize, and monitor expenses to maintain accurate financial records.",
        img: "05"
    },
    {
        id: 6,
        title: "Invoice Generation",
        content: "Generate branded, accurate invoices for students, with tracking for timely payment follow-ups.",
        img: "06"
    },
    {
        id: 7,
        title: "Course and Batch Management",
        content: "Schedule classes, assign batches, and organize curricula with flexible course and batch settings.",
        img: "07"
    },
    {
        id: 8,
        title: "Attendance Tracking",
        content: "Track student and teacher attendance, send alerts, and maintain reports.",
        img: "08"
    },
    {
        id: 9,
        title: "Reports and Analytics",
        content: "Generate comprehensive reports on attendance, fees, and performance for data-driven decisions.",
        img: "09"
    },
    {
        id: 10,
        title: "Settings and Customization",
        content: "Customize user roles, permissions, and system settings to meet the centre’s unique requirements.",
        img: "1010"
    }
];


export const howWorks = "Tuition Management Software simplifies the administration of a tuition centre by consolidating key processes into a single platform. It captures enquiries, tracks lead, and seamlessly manages admissions. The software organizes student data, schedules classes, and assigns batches, making onboarding and batch management effortless. Fee and payment management tools allow centres to set up fee structures, collect payments, and send reminders automatically. Attendance tracking ensures easy monitoring of student and teacher participation, while reporting tools provide insights into financials and academic performance. With streamlined operations, tuition centres can focus more on delivering quality education."